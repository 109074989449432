const hubspotIdentifyUser = (userData, inTeams) => {
  var _hsq = (window._hsq = window._hsq || [])
  _hsq.push([
    'identify',
    {
      email: userData.mail
    }
  ])
  _hsq.push(['setPath', '/#/home'])
  _hsq.push(['trackPageView'])
}

export { hubspotIdentifyUser }
