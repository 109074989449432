import * as React from 'react'
import { createContext, useContext, useReducer, useState } from 'react'
import { PersonaPresence } from 'office-ui-fabric-react'
import getProfilePicWithOptimize from '../helpers/ProfilePicOptimizeHelper'
import UserStore from '../stores/userStore'
import axios from 'axios'
import { fetchPresence } from '../helpers/GraphHelpers'

const idMap = new Map()

export const PersonaContext = createContext({
  getImg: (upn: string, userStore: UserStore) => {},
  getPresence: (upn: string, oid: string, userStore: UserStore) => {},
  resetState: () => {},
  getUser: (upn: string, props?: any) => {},
  getDrs: (upn: string, oid: string) => {},
  state: {
    images: {},
    presences: {},
    users: {},
    drs: {}
  },
  handleSetIsPrinting: (b: boolean) => {},
  isPrinting: false
})

const reducer = (state, action) => {
  switch (action.type) {
    case 'loading-presence': {
      const newState = { ...state }
      newState.presences[action.upn] = {
        ...(newState.presences[action.upn] || {}),
        presence: PersonaPresence.none,
        isLoading: true
      }
      return newState
    }
    case 'set-presence': {
      const newState = { ...state }
      newState.presences[action.upn] = {
        ...(newState.presences[action.upn] || {}),
        presence: action.presence,
        expirationTime: new Date().setMinutes(new Date().getMinutes() + 1),
        isLoading: false
      }
      return newState
    }
    case 'loading-image': {
      const newState = { ...state }
      newState.images[action.upn] = {
        ...(newState.images[action.upn] || {}),
        image: '',
        isLoading: true
      }
      return newState
    }
    case 'set-image': {
      const newState = { ...state }
      newState.images[action.upn] = {
        ...(newState.images[action.upn] || {}),
        image: action.image,
        isLoading: false
      }
      return newState
    }
    case 'loading-user': {
      const newState = { ...state }
      newState.users[action.upn] = {
        ...(newState.users[action.upn] || {}),
        user: null,
        isLoading: true
      }
      return newState
    }
    case 'set-user': {
      const newState = { ...state }
      newState.users[action.upn] = {
        ...(newState.users[action.upn] || {}),
        user: action.user,
        isLoading: false
      }
      return newState
    }
    case 'loading-drs': {
      const newState = { ...state }
      newState.drs[action.upn] = {
        ...(newState.drs[action.upn] || {}),
        drs: null,
        isLoading: true
      }
      return newState
    }
    case 'set-drs': {
      const newState = { ...state }
      newState.drs[action.upn] = {
        ...(newState.drs[action.upn] || {}),
        drs: action.drs,
        isLoading: false
      }
      return newState
    }
    case 'reset': {
      return action.initialState
    }
  }
}

const staticRequestLoadingState = {
  presences: {},
  images: {},
  users: {},
  drs: {}
}

const PersonaContextProvider = props => {
  const [isPrinting, setIsPrinting] = useState(false)
  const [state, dispatch] = useReducer(
    reducer,
    {
      presences: {},
      images: {},
      users: {},
      drs: {}
    },
    x => x
  )

  const resetState = () => {
    dispatch({
      type: 'reset',
      initialState: {
        presences: {},
        images: {},
        users: {},
        drs: {}
      }
    })
  }

  React.useEffect(() => {
    const div = window.document.createElement('div')
    window.document.body.appendChild(div)
    div.setAttribute('id', 'mousePosition')
    window.onmousemove = function (e) {
      div.dataset.x = e.clientX.toString()
      div.dataset.y = e.clientY.toString()
    }
  }, [])

  const getImg = async (upn: string, userStore: UserStore) => {
    const { image } = state.images[upn] || {}
    let demoMode = localStorage.getItem('demoMode')

    if (demoMode !== 'true' && (image || staticRequestLoadingState.images[upn])) {
      return
    }
    staticRequestLoadingState.images[upn] = true
    dispatch({ type: 'loading-image', upn })
    const img = await getProfilePicWithOptimize(userStore, upn)
    staticRequestLoadingState.images[upn] = false
    dispatch({ type: 'set-image', upn, image: img })
  }

  const getUser = async (upn: string, props?: any) => {
    try {
      const { user } = state.users[upn] || {}
      if (user || staticRequestLoadingState.users[upn]) {
        return
      }
      dispatch({ type: 'loading-user', upn })
      staticRequestLoadingState.users[upn] = true
      const type = props ? props.type : undefined
      const u = await axios.get('/api/common/getUserForPersona', {
        params: {
          upn,
          type
        }
      })
      idMap.set(upn, u.data.id)
      dispatch({ type: 'set-user', upn, user: u.data })
    } catch (e) {
      console.log(e)
    }
    staticRequestLoadingState.users[upn] = false
  }

  const getDrs = async (upn: string, oid?: string) => {
    try {
      const { drs } = state.drs[upn] || {}
      if (drs || staticRequestLoadingState.drs[upn]) {
        return
      }
      staticRequestLoadingState.drs[upn] = true
      dispatch({ type: 'loading-drs', upn })
      const d = await axios.get('/api/common/get-drs', {
        params: {
          upn,
          oid: oid || idMap.get(upn)
        }
      })
      dispatch({ type: 'set-drs', upn, drs: d.data })
    } catch (e) {
      console.log(e)
      dispatch({ type: 'set-drs', upn, drs: 'NO_DRS' })
    }
    staticRequestLoadingState.drs[upn] = false
  }

  const getPresence = async (upn: string, oid: string, userStore: UserStore) => {
    try {
      if (!upn) {
        return
      }
      const { presence, expirationTime } = state.presences[upn] || {}
      if (staticRequestLoadingState.presences[upn]) {
        return
      }
      if (presence && expirationTime > new Date().getTime()) {
        return
      }

      let id = oid || state?.users[upn]?.user?.id || idMap.get(upn)
      if (!id) {
        await getUser(upn)
      }
      staticRequestLoadingState.presences[upn] = true
      dispatch({ type: 'loading-presence', upn })
      const p = await fetchPresence(id || idMap.get(upn), userStore.graphAccessToken)
      dispatch({ type: 'set-presence', upn, presence: p })
    } catch (e) {
      //console.log(e)
    }
    staticRequestLoadingState.presences[upn] = false
  }

  return (
    <PersonaContext.Provider
      value={{
        getImg,
        getPresence,
        getUser,
        getDrs,
        state,
        isPrinting,
        handleSetIsPrinting: (boolean: boolean) => setIsPrinting(boolean),
        resetState
      }}
    >
      {props.children}
    </PersonaContext.Provider>
  )
}

const usePersona = () => {
  return useContext(PersonaContext)
}

export { PersonaContextProvider, usePersona }
